import * as React from "react"
import { Layout } from "../components/layout"
import Image from '../components/Image'
import { RiSendPlane2Line } from "react-icons/ri"
import { Seo } from "../components/seo"
import GoBack from "../components/goBack"













export default function GearPage() {
   return (


    
    <Layout className="thanks-page">

{/* <Seo title={`Todd's overlanding Subaru road trip setup`} /> */}
 
 
    
    <div className="container" style={{padding:'0 12%'}}>
       
       
    <h1 className="title" style={{fontSize:'50px'}}>Overlanding road trip setup</h1>

    
    <div className="content">
    
    <p>I get a lot of questions from people about overlanding and my road-trip setup. So, I thought I’d post a bit more about my setup.</p>
    
  
    <Image alt="Todd Lambert Web development for photographers" filename="twilightscapes-rig.jpg" />
    
        <Image alt="Todd Lambert Web development for photographers" filename="overlanding.jpg" />
    
    
    <h3>Night Photographer’s Dream Overlanding Setup</h3>

    <p>Up front is a 2014 Subaru Outback with the upgraded package that has an H6 Boxer engine. It’s got everything to go wherever I want to go and is complete with full-size spare, farm jack, shovel, 12ft. ladder, and Rigid Industries LED flood/spot combo light bar.
 All of this is on top of the Yakima large cargo rack.
    The lights are controlled from a toggle switch on the dashboard.
    The Outback has had electronic brake controls added, a sway bar, and transmission cooler.</p>
    
    
     <Image alt="Todd Lambert Web development for photographers" filename="overlanding2.jpg" />

    
    <p>My living quarters and office is in the back: an 18ft. Aliner Expedition pop-up hard-sided A-Frame. It’s got running water, fridge, heater/AC, stove, shower/toilet, queen bed and my office area.</p>

    <h3>Rollin'</h3>

    <p>Overall, it allows me to roll anywhere I want, even in backcountry. While overlanding, I can be completely off the grid for over a month, with dual deep cycle marine batteries, dual propane tanks and soon to come, solar panels.</p>

    <p>In addition to the inside shower, there is also an outside shower which comes in handy to hose things down, or for taking nude showers out in the middle of nowhere. </p>
    
    
    
    
    
    
    
     <Image alt="Todd Lambert Web development for photographers" filename="overlanding4.jpg" />

    <h3>Grillin' &amp; Chillin'</h3>
    <p>My setup even has indoor and outdoor speakers and bluetooth stereo which I use a lot with my iPhone to control.</p>

    <p>Lastly, there is also a propane access port and there is a “sidekick” BBQ unit that attaches to the side rear. I use this a lot to make toast and grill up quick burgers and what not. Life in the field, doesn’t have to be harsh! It’s certainly not 5-star accommodations, but the views can’t be beat!</p>
    
    
 <Image alt="Todd Lambert Web development for photographers" filename="overlanding3.jpg" />
    <h3>Conclusion</h3>
    <p>I’ve successfully managed, living in this through Colorado snow storms, Utah sandstorms, Oregon rainstorms, you name it, this setup has not failed me yet.</p> 
    
</div>
 </div>
      
      
      


       <div className="container"  style={{padding:'0 12%'}}>
              <h2 className="">
                Todd&apos;s Gear
              </h2>
              
<p>I’ve shot many digital cameraS and used a lot of equipment over the years but some of these tools I will have for the rest of my life. I've gotten tired of chasing the latest thing. This is a list of what I use and enjoy.</p>


<h3>Cameras:</h3>
<ul><li>Canon 5D III</li>
<li>Sony A7R II</li>
<li>Sony A7S II</li></ul>

<h3>Glass:</h3>

<ul><li>8-15 L Fisheye</li>
<li>17 ƒ4 TS-E</li>
<li>24 TS-E II</li>
<li>24 ƒ1.4 L II</li>
<li>35 ƒ1.4 L</li>
<li>16-35mm ƒ2.8 L Mk II</li>
<li>85 ƒ1.2 L Mk II</li>
<li>24-105mm ƒ4 L</li>
<li>135 ƒ2 L</li>
<li>Nikon 14-24</li></ul>




<h3>Other Glass:</h3>

<ul><li>EF Extender 1.4 II</li>
<li>82mm B+W MRC CPL Filter</li>
<li>77mm B+W MRC CPL Filter</li>
<li>72mm B+W MRC CPL Filter</li></ul>

<h3>Accessories:</h3>

<ul><li>Kenko Ext Tubes</li>
<li>Sennheiser 300 Mic</li>
<li>Poverty Wizards – RF-602s (3 sets)</li>
<li>Cokin Filter System</li>
<li>Several sets of Eneloops</li>
<li>Various other tools, gadgets and doo-dads</li></ul>


<h3>Lighting:</h3>

<ul><li>(3) 580EX II Speedlights</li>
<li>Sekonic L-308S Light Meter</li>
<li>Light stands, umbrellas, softboxes, and other diffusers</li>
<li>Some home made stuff (flashlights, lasers, neon, etc..)</li></ul>



<h3>Support:</h3>

<ul><li>Gitzo GT5562GTS</li>
<li>Gitzo 1554T</li>
<li>Gitzo 3531s</li>
<li>Acratech GV2 Ballhead</li>
<li>Acratech GPS-SS Ballhead</li>
<li>Acratech Pano Head</li>
<li>Acratech Long Lens Head</li>
<li>Actratech plates</li>
<li>Cotton Carrier System</li></ul>

<h3>Bags:</h3>

<ul><li>Domke F2</li>
<li>Domke F5xb</li>
<li>Domke Gripper Strap</li>
<li>Domke Backpack Strap</li>
<li>ApeCase Backpack</li>
<li>Booq Python XL and Vyper XL2</li>
<li>Original Lowepro Backpack</li>
<li>Lowepro AW100</li>
<li>Pelican</li></ul>

<br />
<GoBack />

       </div>
    
    </Layout>

  )
}
